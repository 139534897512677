// Tags used only for admin uses - they tags will also be hidden from the public
const adminTags = ['1', '2', '3', 'catalogue', 'featured', 'banner']

// Routes that should not have sponsors in the footer
const hideFooterSponsorRoutes = ['/workshop-sponsors', 'sponsorship', '/technology-catalogue']

// The initiative type id for the sponsors initiative type
const sponsorsTypeId =
   process.env.NODE_ENV === 'production' ? '63c4cfa1d079be0c0d28f033' : '63c4c1ee9290aff87ea4c61e'

export {
   adminTags,
   hideFooterSponsorRoutes,
   sponsorsTypeId
}
