// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useContext, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { Button, Form, Grid, GridCell, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { objectSettingsDisplayModes } from 'config/constants/ui'
import { initiativeUrl } from 'config/constants/urls'
import { Date, Input, Time, TokensInput } from 'src/sites/kits/Utils/ConnectedForm'
import { useFileUploads } from 'src/sites/kits/Utils'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { useMe, useOrganizationWithOptions, useUpdateInitiative,
   useUpdateOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// =======================================================
// Object Settings Editor
// =======================================================

const ObjectSettingsGeneral = ({
   backButtonUrl,
   descriptionInputMinHeight,
   displayMode,
   editorToolbarConfig,
   elements,
   humanizedTypeNameSingular: customHumanizedTypeNameSingular,
   nameInputStyle,
   onCancelButtonClick,
   onUpdate,
   tmpHidePrivacyControls,
   toolbarButtonWidth,
   toolbarJustifyContent
}) => {
   const editorRef = useRef(null)
   const history = useHistory()
   const [isSaving, setIsSaving] = useState(false)
   const [isPublishing, setIsPublishing] = useState(false)

   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const { organization } = useOrganizationWithOptions({ withGlobalNavLinksTargetMetadata: true })
   const { updateOrganization } = useUpdateOrganization({
      variables: {
         withGlobalNavLinksTargetMetadata: true
      }
   })

   // const stylesheet = style.stylesheet({ ...organization.typeStyles })
   const isSingleColumnDisplayMode = displayMode === objectSettingsDisplayModes.SINGLE_COLUMN
   const isDoubleColumnDisplayMode = displayMode === objectSettingsDisplayModes.DOUBLE_COLUMN

   // TODO: Do not rely on ancestors
   // See: #862
   const { initiative, ancestors } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { showNotification } = useContext(NotificationManagerContext)
   const coverMediaUrl = initiative.coverMedia?.file.thumbnailUrlW480
   const parentInitiative = ancestors.length > 0
      ? ancestors[ancestors.length - 1]
      : null

   const initiativeType = organization?.initiativeTypes.find(t => t.id === initiative.type.id)
   const defaultInitiativeBody = initiativeType?.defaultBody?.replace('\n', '<br />') ?? ''
   const initiativeBody = initiative.body.elements?.[0]?.body || defaultInitiativeBody
   const globalNavLink = organization?.globalNavLinks.find(l => l.initiative?.id === initiative.id)

   // TODO: Handle other types of privacy PR #956
   const isDraft = ['admin', 'draft'].includes(initiative.privacy)

   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetId: initiative.id, targetType: 'initiative' })

   // TODO: Generalize
   // See: PR #448, Issue #452
   const editorEventConfigProps = {
      events: {
         'image.beforeUpload': async (images) => {
            const currentEditor = editorRef.current.editor
            const { uploadedMediaUrl: imgUrl } = await uploadFile({ file: images[0], purpose: 'contentEmbed', name: 'embedded' })
            currentEditor.image.insert(imgUrl, null, null, currentEditor.image.get())
         }
      }
   }

   // TODO: This whole thing needs to be cleaned up and made much nicer and more modular
   // PR #849
   // We use `parseZone` to ensure tz offset is preserved and the
   // time isn't converted to local time!!!! LOL!!
   const eventStartDateTimeMoment = useMemo(() => (
      parentInitiative?.startDateTimeISO8601
         ? moment.parseZone(parentInitiative.startDateTimeISO8601, moment.ISO_8601).startOf('day')
         : undefined
   ), [parentInitiative?.startDateTimeISO8601])

   const eventEndDateTimeMoment = useMemo(() => (
      parentInitiative?.endDateTimeISO8601
         ? moment.parseZone(parentInitiative.endDateTimeISO8601, moment.ISO_8601).endOf('day')
         : undefined
   ), [parentInitiative?.endDateTimeISO8601])

   const sessionStartDateTimeMoment = useMemo(() => (
      initiative?.startTime
         ? moment.parseZone(initiative.startDateTimeISO8601, moment.ISO_8601)
         : undefined
   ), [initiative?.startDateTimeISO8601])

   const sessionEndDateTimeMoment = useMemo(() => (
      initiative?.endTime
         ? moment.parseZone(initiative.endDateTimeISO8601, moment.ISO_8601)
         : undefined
   ), [initiative?.endDateTimeISO8601])

   const eventDateTimeUtcOffset = eventStartDateTimeMoment?.utcOffset()
   const isDateOutsideEventRange = (date) => {
      // Convert the date being tested to the event's timezone offset
      // If we didn't do this, it would work only some of the time.
      date.utcOffset(eventDateTimeUtcOffset, true)
      return date.isBefore(eventStartDateTimeMoment) || date.isAfter(eventEndDateTimeMoment)
   }

   const hasCoverMedia = elements.includes('coverMedia')
   const hasDate = elements.includes('startDate')
   const hasDescription = elements.includes('description')
   const hasLocationString = elements.includes('locationString')
   const hasPublishDate = elements.includes('publishDate')
   const hasSlug = elements.includes('slug')
   const hasSubtitle = elements.includes('subtitle')
   const hasTags = elements.includes('tags')
   const hasUrl = elements.includes('url')
   const humanizedTypeNameSingular = customHumanizedTypeNameSingular || initiative.type.nameSingular

   let publishButtonMode = isPublishing ? 'loading' : 'normal'
   let saveButtonMode = isSaving ? 'loading' : 'normal'
   if (isSaving) {
      publishButtonMode = 'disabled'
   }

   if (uploadsInProgress.length) {
      saveButtonMode = 'disabled'
   }

   const initialValues = {
      name: initiative.name
   }

   if (hasSubtitle) {
      initialValues.subtitle = initiative.subtitle || ''
   }

   if (hasLocationString) {
      initialValues.location = {
         name: initiative.location?.name || ''
      }
   }

   if (hasDate) {
      initialValues.date = sessionStartDateTimeMoment
      initialValues.startTime = sessionStartDateTimeMoment
      initialValues.endTime = sessionEndDateTimeMoment
   }

   if (hasSlug) {
      initialValues.slug = initiative.slug || ''
   }

   if (hasTags) {
      initialValues.tags = initiative.tags || []
   }

   if (hasUrl) {
      initialValues.url = initiative.url || ''
   }

   if (isGatherDeveloper) {
      initialValues.logoIconName = initiative.logoIconName || ''
      initialValues.logoIconColor = initiative.logoIconColor || ''
      initialValues.tags = initiative.tags || []
   }

   if (hasPublishDate) {
      const publishDateTimeMoment = useMemo(() => (
         initiative?.publishDateTimeISO8601
            ? moment.parseZone(initiative.publishDateTimeISO8601, moment.ISO_8601)
            : undefined
      ), [initiative?.publishDateTimeISO8601])

      initialValues.publishDate = publishDateTimeMoment
   }

   const handleUpdateInitiative = async (values, actions) => {
      if (uploadsInProgress.length > 0) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         actions.setSubmitting(false)
         return
      }

      delete uploadedFiles.embedded

      try {
         let payload = {
            ...uploadedFiles,
            ...values
         }

         if (hasDate) {
            const { date, startTime, endTime } = values
            payload = {
               ...payload,
               date: undefined,
               startDate: date ? date.format('YYYY-MM-DD') : null,
               startTime: startTime ? startTime.format('HH:mm:ss') : null,
               endDate: date ? date.format('YYYY-MM-DD') : null,
               endTime: endTime ? endTime.format('HH:mm:ss') : null
            }
         }

         if (hasDescription) {
            payload.body = [{
               operation: 'updateRichText',
               updateRichText: {
                  id: initiative.body.elements[0].id,
                  body: editorRef.current.editor.html.get(true)
               }
            }]
         }

         const result = await updateInitiative({ id: initiative.id }, payload)
         const updatedInitaitive = result.data.updateInitiative

         // Note: We manually sync globalNavLinks with initiative name
         // We had discussed doing this automatically through the API
         // but decided to wait as there may be some edge cases where you
         // might want a different GlobalNav link name from initiative name
         // (ie. if initiative name is very long)
         if (globalNavLink && payload.name !== globalNavLink.name) {
            await updateOrganization({
               globalNavLinks: [{
                  operation: 'updateInternalLink',
                  updateInternalLink: {
                     id: globalNavLink.id,
                     name: payload.name
                  }
               }]
            })
         }

         if (onUpdate) {
            onUpdate()
         } else if (payload.slug !== updatedInitaitive.slug) {
            history.push(updatedInitaitive.gatherUrl)
         }

         showNotification({
            message: 'Page updated successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
         setIsSaving(false)
         setIsPublishing(false)
         resetFileUploadState()
      }
   }

   return (
      <Formik
         initialValues={initialValues}
         onSubmit={handleUpdateInitiative}
         render={({ handleSubmit, isSubmitting, setFieldValue }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f1f1f1"
               labelTextColor="#000"
               labelTextSize="2.5"
               labelTextTransform="none"
               labelTextWeight="medium"
               onSubmit={handleSubmit}>
               <View
                  display="flex"
                  flexFlow={isDoubleColumnDisplayMode ? 'row wrap' : 'column'}
                  position="absolute"
                  top="0px"
                  right="0px"
                  left="0px"
                  bottom="70px"
                  scroll="on">
                  <View width={isDoubleColumnDisplayMode ? 'calc(100% - 300px)' : '100%'}>
                     {!tmpHidePrivacyControls && isDraft && (
                        <View
                           width="100%"
                           padding="20px 30px"
                           backgroundColor="rgba(255, 235, 131, 0.2)">
                           <Text size="1.5[a-b] 2[c-f]" color="rgba(163, 117, 75, 1)">
                              You are currently in Draft mode
                           </Text>
                        </View>
                     )}
                     <View width="100%" padding="20px">
                        <Grid columns="12">
                           <GridCell colspan="12">
                              <Text size="2" color="#222" weight="medium">
                                 {`${humanizedTypeNameSingular} Name`}
                              </Text>
                              <Text size="2" color="#aaa">
                                 (max 100 characters)
                              </Text>
                              <Spacer size="1.5" />
                              <Input
                                 name="name"
                                 size="lg"
                                 maxLength="100"
                                 style={nameInputStyle}
                              />
                           </GridCell>
                           {hasSubtitle && (
                              <GridCell colspan="12">
                                 <Text size="2" color="#222" weight="medium">
                                    Subtitle
                                 </Text>
                                 <Spacer size="1.5" />
                                 <Input name="subtitle" size="md" />
                              </GridCell>
                           )}
                           {hasDate && (
                              <>
                                 <GridCell colspan="4">
                                    <Text size="2" color="#222" weight="medium">
                                       Date
                                    </Text>
                                    <Spacer size="1.5" />
                                    <Date
                                       isOutsideRange={isDateOutsideEventRange}
                                       name="date"
                                    />
                                 </GridCell>
                                 <GridCell colspan="4">
                                    <Text size="2" color="#222" weight="medium">
                                       Start Time
                                       {initiative.timezoneAbbr ? ` (${initiative.timezoneAbbr})` : ''}
                                    </Text>
                                    <Spacer size="1.5" />
                                    <Time name="startTime" />
                                 </GridCell>
                                 <GridCell colspan="4">
                                    <Text size="2" color="#222" weight="medium">
                                       End Time
                                       {initiative.timezoneAbbr ? ` (${initiative.timezoneAbbr})` : ''}
                                    </Text>
                                    <Spacer size="1.5" />
                                    <Time name="endTime" />
                                 </GridCell>
                              </>
                           )}
                           {hasUrl && (
                              <GridCell colspan="12">
                                 <Text size="2" color="#222" weight="medium">
                                    URL
                                 </Text>
                                 <Spacer size="1.5" />
                                 <Input name="url" size="md" />
                              </GridCell>
                           )}
                           {hasLocationString && (
                              <GridCell colspan="12">
                                 <Text size="2" color="#222" weight="medium">
                                    Location
                                 </Text>
                                 <Spacer size="1.5" />
                                 <Input
                                    name="location.name"
                                    placeholder="e.g. Grand Ballroom"
                                    size="md"
                                 />
                              </GridCell>
                           )}
                           {hasDescription && (
                              <GridCell colspan="12">
                                 <Text size="2" color="#222" weight="medium">
                                    Description
                                 </Text>
                                 <Spacer size="1.5" />
                                 <View display="block" width="100%">
                                    <HtmlEditor
                                       ref={editorRef}
                                       scrollContainerTopOffset="70px"
                                       config={{
                                          heightMin: descriptionInputMinHeight,
                                          paragraphFormat: {
                                             N: 'Body',
                                             pre: 'Code'
                                          },
                                          placeholderText: `Write a short description for this ${humanizedTypeNameSingular}...`,
                                          ...editorEventConfigProps
                                       }}
                                       initialValue={initiativeBody}
                                       // stylesheet={stylesheet}
                                       toolbarConfig={editorToolbarConfig}
                                    />
                                 </View>
                              </GridCell>
                           )}
                           <GridCell colspan="12">
                              <Text size="2" color="#222" weight="medium">
                                 Tags
                              </Text>
                              <Spacer size="1.5" />
                              <TokensInput name="tags" />
                           </GridCell>
                           {hasSlug && (
                              <GridCell colspan="12">
                                 <Text size="2" weight="medium">
                                    {`${initiative.type.nameSingular} URL`}
                                 </Text>
                                 <Text size="2" color="#aaa">
                                    {`This ${initiative.type.nameSingular} is accessible through the following URL:`}
                                 </Text>
                                 <Spacer size="2" />
                                 <View
                                    display="flex"
                                    height="30px"
                                    alignItems="center">
                                    <View
                                       flex="0 0 auto"
                                       display="flex"
                                       height="30px"
                                       alignItems="center"
                                       marginRight="12px">
                                       <Text size="1.5" color="#999">
                                          {`domain.com${initiativeUrl(initiative).split('/').slice(0, -1).join('/')}/`}
                                       </Text>
                                    </View>
                                    <View flex="1 1 auto">
                                       <Input size="sm" name="slug" required />
                                    </View>
                                 </View>
                              </GridCell>
                           )}
                           {isGatherDeveloper && (
                              <>
                                 <GridCell colspan="6">
                                    <Text size="2" weight="medium">
                                       Page Icon Name
                                    </Text>
                                    <Spacer size="1.5" />
                                    <Input size="sm" name="logoIconName" />
                                 </GridCell>
                                 <GridCell colspan="6">
                                    <Text size="2" weight="medium">
                                       Page Icon Color
                                    </Text>
                                    <Spacer size="1.5" />
                                    <Input size="sm" name="logoIconColor" />
                                 </GridCell>
                              </>
                           )}
                        </Grid>
                     </View>
                  </View>
                  <View
                     backgroundColor={isDoubleColumnDisplayMode && '#fafafa'}
                     borderLeft={isDoubleColumnDisplayMode && '1px solid #eee'}
                     width={isDoubleColumnDisplayMode ? '300px' : '100%'}
                     padding={isDoubleColumnDisplayMode ? '20px' : '0px 20px'}>
                     <Grid>
                        {hasCoverMedia && (
                           <GridCell colspan="12">
                              <Text size="2" color="#222" weight="medium">
                                 Thumbnail Image
                              </Text>
                              <View
                                 float="left"
                                 flex="0 0 auto"
                                 width="100%"
                                 maxWidth={isSingleColumnDisplayMode && '150px'}
                                 marginTop="10px"
                                 borderRadius="3px"
                                 border="1px solid #ccc">
                                 <View
                                    float="left"
                                    width="100%"
                                    height="100%"
                                    borderRadius="3px"
                                    backgroundColor="#eee"
                                    padding="6px">
                                    <ImageUpload
                                       onFileChange={uploadFile}
                                       aspectRatio={0.6}
                                       name="coverMedia"
                                       emptyMessage="No cover image set"
                                       purpose="coverPhoto"
                                       src={coverMediaUrl}
                                    />
                                 </View>
                              </View>
                           </GridCell>
                        )}
                        {hasPublishDate && (
                           <GridCell colspan="12">
                              <View
                                 float="left"
                                 width="100%">
                                 <Text size="2" weight="medium" color="#333">
                                    Publish Date
                                 </Text>
                                 <Spacer size="1" />
                                 <Date
                                    anchorDirection="right"
                                    name="publishDate"
                                 />
                              </View>
                           </GridCell>
                        )}
                     </Grid>
                  </View>
               </View>
               <View
                  display="flex"
                  justifyContent={toolbarJustifyContent}
                  alignItems="center"
                  position="absolute"
                  right="0px"
                  left="0px"
                  bottom="0px"
                  height="70px"
                  borderTop="1px solid #eee"
                  padding="0px 20px">
                  <Button
                     onClick={onCancelButtonClick}
                     width={toolbarButtonWidth}
                     name="Cancel"
                     padding="25px"
                     size="md"
                     color="#eee"
                     textColor="#333"
                  />
                  <View width="5px" height="30px" />
                  <Button
                     onClick={() => {
                        setIsSaving(true)
                        // This is in the event the user attempted to post. failed,
                        // then saved draft
                        if (isDraft) {
                           setFieldValue('privacy', 'draft', false)
                        }
                        handleSubmit()
                     }}
                     width={toolbarButtonWidth}
                     color={isSaving ? '#222' : '#000'}
                     mode={saveButtonMode}
                     name="Save Changes"
                     padding="25px"
                     size="md"
                     textColor="#fff"
                     type="submit"
                  />
                  {!tmpHidePrivacyControls && isDraft && (
                     <>
                        <View width="5px" height="30px" />
                        <Button
                           onClick={() => {
                              setIsPublishing(true)
                              setFieldValue('privacy', 'inherit', false)
                              handleSubmit()
                           }}
                           color={isPublishing ? '#222' : '#000'}
                           mode={publishButtonMode}
                           padding="25px"
                           size="md"
                           textColor="#fff"
                           type="submit"
                           name="Post"
                        />
                     </>
                  )}
               </View>
            </Form>
         )}
      />
   )
}

ObjectSettingsGeneral.propTypes = {
   backButtonUrl: PropTypes.string,
   displayMode: PropTypes.oneOf(Object.values(objectSettingsDisplayModes)),
   descriptionInputMinHeight: PropTypes.string,
   editorToolbarConfig: PropTypes.oneOf(['advanced', 'textBasic']),
   elements: PropTypes.arrayOf(
      PropTypes.oneOf([
         'coverMedia',
         'description',
         'endDate',
         'endTime',
         'locationString',
         'publishDate',
         'slug',
         'subtitle',
         'startDate',
         'startTime',
         'tags',
         'url'
      ])
   ),
   humanizedTypeNameSingular: PropTypes.string,
   nameInputStyle: PropTypes.object, // to customize inline style of name input field
   onCancelButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func,
   // Temporary prop until we have more information
   // This is to prevent things like: status banner, "Post" button from showing up
   // for initiatives like custom and media pages where it doesn't make sense
   // to show these controls
   tmpHidePrivacyControls: PropTypes.bool,
   toolbarButtonWidth: PropTypes.string,
   toolbarJustifyContent: PropTypes.oneOf(['flex-end', 'space-between'])
}

ObjectSettingsGeneral.defaultProps = {
   backButtonUrl: undefined,
   descriptionInputMinHeight: '200px',
   displayMode: objectSettingsDisplayModes.SINGLE_COLUMN,
   editorToolbarConfig: 'textBasic',
   elements: [],
   humanizedTypeNameSingular: undefined,
   nameInputStyle: undefined,
   onUpdate: undefined,
   tmpHidePrivacyControls: false,
   toolbarButtonWidth: 'auto',
   toolbarJustifyContent: 'flex-end'
}

export default ObjectSettingsGeneral
