// =========================================================================================@@
// Last Updated Date: Mar 13, 2025
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import classNames from 'classnames'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { sponsorsTypeId } from 'src/sites/custom/banff-pipeline-workshop/constants'

// Initiative slugs that sponsor block should actually appear
const permissibleInitiativeSlugs = ['about', 'home', 'bpw-2025']

const BPWSponsorsBlock = ({
   filterTag,
   gridClassName,
   initiativeSlug,
   title,
   titlePaddingClassName
}) => {
   const { organization } = useOrganization()

   if (
      organization.slug !== 'banff-pipeline-workshop' ||
      !permissibleInitiativeSlugs.includes(initiativeSlug)
   ) {
      return null
   }

   const { initiativeList: sponsorList } = useInitiativeList({
      archived: 'exclude',
      typeIds: sponsorsTypeId,
      limit: 100,
      sortBy: 'name'
   })

   const sponsors = filterTag
      ? sponsorList.items.filter(sponsor => sponsor.tags.includes(filterTag))
      : sponsorList.items

   return (
      <View width="100%" marginBottom="40px" borderTop="1px solid #eee">
         <div className={classNames('flex justify-between items-center', titlePaddingClassName)}>
            <div className="text-xs font-semibold uppercase opacity-50">{title}</div>
            <Link to="/workshop-sponsors" className="text-2xs uppercase opacity-50">
               See all
            </Link>
         </div>
         <div className={classNames('grid', gridClassName)}>
            {sponsors.map(sponsor => (
               <a
                  key={sponsor.id}
                  href={sponsor.url || '#'}
                  className="px-2 py-2"
                  target="_blank"
                  rel="noopener noreferrer">
                  <View
                     width="100%"
                     paddingTop="60%"
                     backgroundImage={`url(${sponsor.coverMedia?.file.thumbnailUrlW480})`}
                     backgroundSize="contain"
                     backgroundRepeat="no-repeat"
                     backgroundPosition="center center"
                  />
               </a>
            ))}
         </div>
      </View>
   )
}

BPWSponsorsBlock.propTypes = {
   filterTag: PropTypes.string,
   gridClassName: PropTypes.string,
   initiativeSlug: PropTypes.string.isRequired,
   title: PropTypes.string,
   titlePaddingClassName: PropTypes.string
}

BPWSponsorsBlock.defaultProps = {
   filterTag: null,
   gridClassName: 'grid-cols-3 sm:grid-cols-4 md:grid-cols-7 gap-8 md:gap-12',
   title: 'Event Sponsors',
   titlePaddingClassName: 'py-8'
}

export default BPWSponsorsBlock
