// =========================================================================================@@
// Last Updated Date: Feb 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, FullscreenOutline24Icon } from 'assets/icons'
import { Button, Subtitle, Title } from 'src/sites/kits/UI'
import screenfull from 'screenfull'
import style from './style.less'

let overlayVisibilityTimer

const TVControlsOverlay = ({ children, returnUrl, subtitle, title, showDuration }) => {
   const [overlayIsVisible, setOverlayIsVisible] = useState(false)
   const viewClasses = [style.view]

   if (overlayIsVisible) {
      viewClasses.push(style.overlayIsVisible)
   }

   const handleToggleFullscreenMode = () => {
      if (screenfull.isEnabled) {
         screenfull.toggle()
      }
   }

   const showOverlay = () => {
      setOverlayIsVisible(true)
      document.body.style.cursor = 'default'
   }

   const handleMouseMove = () => {
      if (!overlayIsVisible) {
         clearTimeout(overlayVisibilityTimer)
         showOverlay()

         overlayVisibilityTimer = setTimeout(() => {
            setOverlayIsVisible(false)
            document.body.style.cursor = 'none'
         }, showDuration)
      }
   }

   const handleHideOverlay = () => {
      clearTimeout(overlayVisibilityTimer)
      setOverlayIsVisible(false)
      document.body.style.cursor = 'none'

      // Below is because we need to prevent overlay from showing
      // immediately after hiding (due to small mouse movements)
      document.removeEventListener('mousemove', handleMouseMove)
      setTimeout(() => {
         document.addEventListener('mousemove', handleMouseMove)
      }, 400)
   }

   useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove)

      return () => {
         clearTimeout(overlayVisibilityTimer)
         document.removeEventListener('mousemove', handleMouseMove)
         document.body.style.cursor = 'default'
      }
   }, [])

   return (
      <div className={classNames(viewClasses)}>
         <View
            display="flex"
            alignItems="center"
            position="absolute"
            top="40px"
            right="40px">
            <View
               display="flex"
               alignItems="center"
               marginRight="40px"
               onClick={handleToggleFullscreenMode}
               className={style.button}>
               <View marginRight="16px" style={{ color: '#fff' }}>Fullscreen</View>
               <FullscreenOutline24Icon
                  width="24px"
                  height="24px"
                  color="#fff"
                  strokeWidth="2px"
               />
            </View>
            <Link to={returnUrl}>
               <View display="flex" alignItems="center" className={style.button}>
                  <View marginRight="16px" style={{ color: '#fff' }}>Exit</View>
                  <CloseIcon
                     width="24px"
                     height="24px"
                     color="#fff"
                     strokeWidth="2px"
                  />
               </View>
            </Link>
         </View>
         <View
            height="540px"
            padding="40px 40px">
            <View width="680px">
               <Title size="lg" color="#fff">{title}</Title>
               <View width="100%" padding="24px 0px 40px 0px">
                  <Subtitle color="rgba(255,255,255,0.6)">{subtitle}</Subtitle>
               </View>
            </View>
            {children?.(handleHideOverlay)}
            <View display="none" flex="0 0 auto">
               <Button
                  onClick={handleHideOverlay}
                  name="Return to Feed"
                  color="#fff"
                  textColor="#222"
                  height="var(--baseComponentHeight-xl)"
               />
            </View>
         </View>
      </div>
   )
}

TVControlsOverlay.propTypes = {
   children: PropTypes.func,
   returnUrl: PropTypes.string,
   showDuration: PropTypes.number,
   subtitle: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
}

TVControlsOverlay.defaultProps = {
   children: undefined,
   returnUrl: '/',
   showDuration: 1500
}

export default TVControlsOverlay
