import React from 'react'
import Logo from 'src/sites/kits/Global/components/MainMenu/Logo'

const TVScreenSaver = () => {
   return (
      <div
         className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-white">
         <Logo size="50" />
      </div>
   )
}

export default TVScreenSaver
