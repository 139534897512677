// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext, useEffect, useRef, useState } from 'react'
import mustache from 'mustache'
import { Button, Form, Grid, GridCell, NotificationManagerContext,
   Spacer, Textarea, Text, View } from 'oio-react'
import { stringify as stringifyToQueryString } from 'query-string'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import EmailMessageIcon from 'assets/icons/emailMessage'
import { useStatefulRef } from 'src/sites/kits/Utils'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import sanitizeHtml from 'sanitize-html'

// =======================================================
// Template Rendering Helpers/Config
// =======================================================

const allowedTags = [
   'a', 'b', 'br', 'blockquote', 'code', 'em',
   'hr', 'i', 'img', 'li', 'ol', 'p', 'pre',
   'u', 'ul', 's', 'span', 'strong', 'sub'
]

const sanitize = val => sanitizeHtml(val, {
   allowedTags,
   allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      a: ['href', 'target', 'style'],
      div: ['style'],
      img: ['alt', 'height', 'src', 'style', 'width'],
      table: ['*'],
      td: ['*']
   },
   allowedStyles: {
      '*': {
         color: [/^[a-zA-Z0-9#]+$/],
         'border-bottom-color': [/^[a-zA-Z0-9#]+$/],
         width: [/^\d+(px|em|%)?$/],
         height: [/^\d+(px|em|%)?$/]
      }
   }
})

mustache.escape = function (value = '') {
   return sanitize(value)
}

// =======================================================
// Token Helpers
// =======================================================

const templateDelimiterLeft = '{{'
const templateDelimiterRight = '}}'
const templateTokenRegex = new RegExp([
   // eslint-disable-next-line no-useless-escape
   templateDelimiterLeft.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'),
   '\\s+[a-zA-Z0-9_.]+\\s+',
   // eslint-disable-next-line no-useless-escape
   templateDelimiterRight.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
].join(''), 'g')

const prettifyTemplateTokens = (textOriginal = '') => {
   let text = textOriginal
   const tokens = text.match(templateTokenRegex) || []

   tokens.forEach((token) => {
      // eslint-disable-next-line no-useless-escape
      const variableName = token.match(/\s+([\w\.]+)\s+/)[0]
      const styledToken = `<span style="border: 1px solid #0f7ce0;padding:3px 9px;margin:0px 4px 1px 1px;color:#0f7ce0;border-radius:12px;font-size:11px;line-height:11px;">${variableName}</span>`
      text = text.replace(token, styledToken)
   })

   return text
}

// =======================================================
// HTML Editor Config
// =======================================================

const htmlEditorConfig = {
   // toolbarInline: true,
   // enter: $.FroalaEditor.ENTER_BR, // eslint-disable-line no-undef
   heightMin: 180,
   toolbarButtons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'formatOL',
      'formatUL',
      'insertLink',
      'html'
   ],
   // TODO: STEVE: SHOULD THIS REFERENCE A CONSTANT SOMEWHERE?
   zIndex: 901
}

// =======================================================
// Component
// =======================================================

const EmailNotificationEditor = ({ onCloseButtonClick, targetType, targetId }) => {
   const { emailNotificationType } = useParams()
   const [template, setTemplate] = useState()
   const { showNotification } = useContext(NotificationManagerContext)
   const emailPreviewFrame = useRef()
   const [editorScrollContainer, setEditorScrollContainer] = useStatefulRef(null)

   // We intentionally don't handle any errors here, since any errors would be fatal.
   // Error boundary will catch such errors and be reported in Sentry.
   const getEmailTemplate = async () => {
      const queryParams = targetType === 'initiative'
         ? { initiative: targetId }
         : {}

      const queryString = stringifyToQueryString(queryParams)
      const response = await apiFetch(`/email-templates/${emailNotificationType}?${queryString}`, {
         headers: { 'Content-Type': 'application/json' }
      })

      if (response.ok) {
         const result = await response.json()
         setTemplate(result)
      } else {
         const result = await response.json()
         throw new Error(result?.message || 'Unexpected Error')
      }
   }

   // Fetch the email template
   useEffect(() => {
      getEmailTemplate()
   }, [])

   // Update the preview in the iframe
   useEffect(() => {
      if (!template) {
         return
      }

      const subject = prettifyTemplateTokens(template.subject)
      const body = prettifyTemplateTokens(mustache.render(template.html, template.data))

      const previewHtml = `
         <div style="float: left; width: 95%; font-family: Helvetica, Arial, Sans-Serif; color: #555;">
            <div style="float: left; width: 100%; padding: 12px 12px 18px 12px; color:#888; font-size: 0.9em; border-bottom: 1px solid #eee;"">
               <span style="color: #555; margin-right: 12px;"><b>Subject</b></span>
               ${subject}
            </div>
            <div style="float: left; width: 90%; padding: 48px 5%; color: #555; font-size: 0.9em; line-height: 1.2em;">
               ${body}
            </div>
         </div>`
         // Remove stray spaces
         .split('\n')
         .map(line => line.trim())
         .join('')

      const emailPreviewFrameContentEl = emailPreviewFrame.current.contentDocument ||
         emailPreviewFrame.current.contentWindow.document

      emailPreviewFrameContentEl.body.innerHTML = previewHtml
   }, [template])

   const handleSubjectChange = (event) => {
      const newSubjectValue = event.target.value
      setTemplate({
         ...template,
         subject: newSubjectValue
      })
   }

   const handleDataFieldChange = (key, value) => {
      setTemplate({
         ...template,
         data: {
            ...template.data,
            [key]: value
         }
      })
   }

   const handleSendPreviewEmail = async () => {
      const { data, subject } = template
      const payload = {
         data,
         subject,
         initiative: targetType === 'initiative'
            ? targetId
            : undefined
      }

      try {
         const response = await apiFetch(`/email-templates/${emailNotificationType}/preview`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
         })

         if (response.ok) {
            showNotification({
               message: 'Email template preview has been sent to you!',
               title: 'Success!',
               type: 'success'
            })
         } else {
            const result = await response.json()
            showNotification({
               message: result?.message || 'An unexpected error occurred',
               title: 'Error!',
               type: 'error'
            })
         }
      } catch (error) {
         showNotification({
            message: error?.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleSubmit = async (event) => {
      event.preventDefault()

      const { data, subject } = template
      const payload = {
         data,
         subject,
         initiative: targetType === 'initiative'
            ? targetId
            : undefined
      }

      try {
         const response = await apiFetch(`/email-templates/${emailNotificationType}`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
         })

         if (response.ok) {
            showNotification({
               message: 'Template updated successfully!',
               title: 'Success!',
               type: 'success'
            })

            // Refetch
            getEmailTemplate()
         } else {
            const result = await response.json()
            showNotification({
               message: result?.message || 'An unexpected error occurred',
               title: 'Error!',
               type: 'error'
            })
         }
      } catch (error) {
         showNotification({
            message: error?.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleResetEmailTemplate = async () => {
      const payload = {
         initiative: targetType === 'initiative'
            ? targetId
            : undefined
      }

      try {
         const response = await apiFetch(`/email-templates/${emailNotificationType}/reset`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
         })

         if (response.ok) {
            showNotification({
               message: 'Template has been rest successfully!',
               title: 'Success!',
               type: 'success'
            })

            // Refetch
            getEmailTemplate()
         } else {
            const result = await response.json()
            showNotification({
               message: result?.message || 'An unexpected error occurred',
               title: 'Error!',
               type: 'error'
            })
         }
      } catch (error) {
         showNotification({
            message: error?.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   if (!template) {
      return null
   }

   const dataFormElements = template.data_schema.map((item) => {
      // NOTE: As of right now, all our template fields are textareas.
      if (item.input.element === 'textarea') {
         return (
            <div key={item.key}>
               <HtmlEditor
                  config={htmlEditorConfig}
                  initialValue={template.data[item.key] || ''}
                  onChange={value => handleDataFieldChange(item.key, value)}
                  // This is to handle toolbar popover being properly appended
                  scrollContainer={editorScrollContainer}
                  scrollContainerTopOffset="70px"
               />
               <Spacer size="2" />
            </div>
         )
      }

      return null
   })

   const availableTokens = template.tokens.map(token => (
      <div
         key={token.key}
         style={{
            display: 'inline-block',
            border: '1px solid #0f7ce0',
            padding: '4px 12px',
            margin: '0px 4px 4px 0px',
            color: '#0f7ce0',
            borderRadius: '18px',
            fontSize: '13px',
            lineHeight: '13px'
         }}>
         {token.key}
      </div>
   ))

   return (
      <View
         float="left"
         width="100%"
         height="100%"
         borderRadius="9px"
         style={{ overflow: 'hidden' }}>
         <View
            float="left"
            width="100%[a-b] 420px[c-f]"
            height="100%"
            borderRight="1px solid #e5e5e5">
            <Form
               elementBackgroundColor="#fff"
               elementBorderRadius="5px"
               onSubmit={handleSubmit}
               style={{ height: '100%' }}>
               <View
                  ref={setEditorScrollContainer}
                  float="left"
                  width="100%"
                  height="calc(100% - 132px)"
                  backgroundColor="#fafafa"
                  scroll="on">
                  <View
                     backgroundColor="#fff"
                     width="100%"
                     padding="36px"
                     borderBottom="1px solid #e5e5e5">
                     <View
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="36px"
                        height="36px"
                        borderRadius="50%"
                        backgroundColor="#000">
                        <EmailMessageIcon
                           width="20px"
                           height="20px"
                           strokeWidth="2px"
                           color="#fff"
                        />
                     </View>
                     <Spacer size="2" />
                     <Text
                        size="6"
                        lineHeight="130%"
                        color="#000"
                        weight="medium">
                        {`${template.title} Notification`}
                     </Text>
                     <Spacer size="2" />
                     <Text size="2" color="#777">
                        {`${template.description} You can customize the email subject and body. Be sure to save your changes.`}
                     </Text>
                  </View>
                  <View
                     width="100%"
                     padding="42px 36px">
                     <Text size="1.5" weight="medium">
                        Available Tokens
                     </Text>
                     <Spacer size="1" />
                     {availableTokens}
                     <Spacer size="3" />
                     <Grid spacing="12px">
                        <GridCell colspan="12">
                           <Text size="1.5" weight="medium">
                              Subject
                           </Text>
                           <Spacer size="1" />
                           <Textarea
                              rows="2"
                              name="subject"
                              onChange={handleSubjectChange}
                              placeholder="Enter Email Subject"
                              defaultValue={template.subject}
                           />
                        </GridCell>
                        <GridCell colspan="12">
                           <Text size="1.5" weight="medium">
                              Body
                           </Text>
                           <Spacer size="1" />
                           <Text size="2">
                              {dataFormElements}
                           </Text>
                        </GridCell>
                     </Grid>
                  </View>
               </View>
               <View
                  float="left"
                  width="100%"
                  borderTop="1px solid #e5e5e5"
                  padding="24px 36px"
                  height="132px">
                  <Grid spacing="12px">
                     <GridCell colspan="6">
                        <Button
                           width="100%"
                           size="md"
                           name="Send Preview Email"
                           onClick={handleSendPreviewEmail}
                           color="#eee"
                           textColor="#333"
                        />
                     </GridCell>
                     <GridCell colspan="6">
                        <Button
                           width="100%"
                           size="md"
                           name="Reset Email"
                           onClick={handleResetEmailTemplate}
                           color="#eee"
                           textColor="#333"
                        />
                     </GridCell>
                     <GridCell colspan="12">
                        <Button
                           width="100%"
                           size="md"
                           name="Save Changes"
                           type="submit"
                           color="#0f7ce0"
                           textColor="#fff"
                        />
                     </GridCell>
                  </Grid>
               </View>
            </Form>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 420px)[c-f]"
            height="100%"
            backgroundColor="#eee">
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               float="left"
               width="100%"
               height="60px"
               padding="0px 18px"
               borderBottom="1px solid #eee">
               <View width="30px" flex="0 0 auto" />
               <Text size="1" weight="medium" color="#666">
                  Below is a preview of your email notitication
               </Text>
               <View
                  width="30px"
                  flex="0 0 auto"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               padding="0px 24px[a-d] 0px 150px[e-f]"
               scroll="on">
               <View
                  float="left"
                  width="100%"
                  height="calc(100% - 30px)"
                  borderRadius="9px"
                  border="1px solid #ddd"
                  backgroundColor="#fff">
                  <iframe
                     title="Email Preview"
                     ref={emailPreviewFrame}
                     frameBorder="0"
                     width="100%"
                     height="100%"
                  />
               </View>
            </View>
         </View>
      </View>
   )
}

EmailNotificationEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string
}

EmailNotificationEditor.defaultProps = {
   targetId: undefined
}

export default EmailNotificationEditor
