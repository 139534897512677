import React from 'react'

const CoverBlock = () => {
   return (
      <div className="communities-content-wrapper pt-16 mb-32">
         {/* <div className="h-[120px]" /> */}
         <div className="lg:h-[90px]" />
         <div className="grid grid-cols-1 xl:grid-cols-2 gap-16" style={{ fontFamily2: 'futura' }}>
            <div className="flex flex-col justify-center 2pt-12">
               {/* <img
                  src="/assets/custom/communities/images/logo.jpg"
                  width="24px"
                  alt="Communities"
               /> */}
               {/* <div className="text-2xl font-semibold tracking-tight text-white">
                  Communities
               </div> */}
               <div className="w-40 mb-2">
                  <img
                     src="/assets/custom/communities/images/communities-logo2.png"
                     height="24px"
                     alt="Communities"
                  />
               </div>
               <div className="w-12 h-1 bg-white my-6" />
               <div
                  className="text-5xl md:text-6xl uppercase2 font-medium tracking-tighter leading-[1.1em] text-white my-8 "
                  style={{ fontFamily: 'georgia' }}>
                  Build a place to bring
                  <br />
                  your community
                  <br />
                  together
                  {/* The all-in-one
                  <br />
                  Community Platform */}
               </div>
               {/* <div className="w-40 h-4 bg-white my-4" /> */}
               <div className="max-w-xl2 text-lg tracking-tight text-white py-4 opacity-50">
                  The simple, all-in-one platform designed to help you build a place for your
                  community to engage, share, and exchange insights, ideas, opportunities and
                  initiatives of shared interest.
               </div>
               {/* <div className="w-full h-[1px] bg-white"></div> */}
               <div>
                  <div
                     className="inline-flex items-center h-16 gap-4 bg-white text-black text-lg 2font-semibold
               mt-8 rounded-full pl-12 pr-12">
                     <a href="mailto:hello@mother.co" className="font-semibold">
                        Book a Demo
                     </a>
                     {/* <ArrowCircleRight size={24} color="black" weight="fill" /> */}
                  </div>
               </div>
            </div>
            <div className="">
               <div className="w-full aspect-square rounded-[60px] overflow-hidden">
                  <video
                     className="w-full h-full object-cover"
                     poster="/assets/custom/communities/images/splash.png"
                     autoPlay
                     muted
                     loop
                     playsInline>
                     <source
                        src="/assets/custom/communities/media/communities-splash.mp4"
                        type="video/mp4"
                     />
                  </video>
               </div>
            </div>
         </div>
      </div>
   )
}

export default CoverBlock
