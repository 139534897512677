/* eslint-disable max-len */
import React from 'react'
import CoverBlock from './CoverBlock'
import LogoBlock from './LogoBlock'
// import PricingBlock from './PricingBlock'
// import FeatureBlockA from './FeatureBlocks/blockA'
// import FeatureBlockB from './FeatureBlocks/blockB'

const CommunitiesHome = () => {
   return (
      <>
         <CoverBlock />
         <LogoBlock />
         {/* <div className="bg-black/20 py-32">
            <div className="communities-content-wrapper">
               <div className="grid grid-cols-2 gap-16" style={{ fontFamily2: 'futura' }}>
                  <div className="col-span-2">
                     <div className="text-center mb-16">
                        <div className="text-lg uppercase font-semibold tracking-tight text-white/80">
                           Features
                        </div>
                        <div
                           className="text-5xl font-medium tracking-tight text-white mt-4"
                           style={{ fontFamily: 'garamond' }}>
                           Everything you need
                        </div>
                     </div>
                     <div className="grid grid-cols-3 gap-8">
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">
                              Community Forums
                           </div>
                           <div className="text-white/60">
                              Foster meaningful discussions with threaded conversations, rich media
                              support, and powerful moderation tools.
                           </div>
                        </div>
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">Live Events</div>
                           <div className="text-white/60">
                              Host virtual meetups, webinars, and workshops with integrated video
                              streaming and interactive features.
                           </div>
                        </div>
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">
                              Resource Library
                           </div>
                           <div className="text-white/60">
                              Share and organize documents, videos, and resources in a searchable
                              knowledge base.
                           </div>
                        </div>
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">
                              Member Directory
                           </div>
                           <div className="text-white/60">
                              Connect members through detailed profiles, direct messaging, and
                              networking features.
                           </div>
                        </div>
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">
                              Analytics Dashboard
                           </div>
                           <div className="text-white/60">
                              Track engagement, growth, and community health with comprehensive
                              analytics and reporting.
                           </div>
                        </div>
                        <div className="bg-white/5 rounded-3xl p-8 hover:bg-white/10 transition-all">
                           <div className="text-2xl font-medium text-white mb-4">
                              Custom Branding
                           </div>
                           <div className="text-white/60">
                              Make it yours with customizable themes, logos, and white-label
                              options.
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
         {/* <FeatureBlockA />
         <FeatureBlockB />
         <FeatureBlockA />
         <PricingBlock /> */}
      </>
   )
}

export default CommunitiesHome
