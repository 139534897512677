// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal } from 'oio-react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { objectSettingsDisplayModes } from 'config/constants/ui'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import DiscussionSettings from 'src/sites/kits/Settings/apps/Discussion'
import EventSettings from 'src/sites/kits/Settings/apps/Event'
import GroupSettings from 'src/sites/kits/Settings/apps/Group'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const bpwSponsorsTypeId = process.env.NODE_ENV === 'production'
   ? '63c4cfa1d079be0c0d28f033'
   : '63c4c1ee9290aff87ea4c61e'

// TODO: This whole component is dumb and should be deprecated in short order
// PR #967
const TmpObjectSettingsRoutes = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { initiative } = useContext(InitiativeHierarchyContext)

   if (!initiative.currentUserCanEdit) {
      return null
   }

   // Discussions have inline images and can have attached files
   if (initiative.class === 'discussion') {
      return (
         <ModalRoute path={`${match.path}/-/settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  overlayBackgroundColor="rgba(45,40,40,0.9)"
                  width="100%[a-b] 90%[c-d] 100%[e-f]"
                  height="100%"
                  maxWidth="1000px"
                  onCloseComplete={onCloseComplete}
                  open={open}
                  zIndex="var(--settingsModalLevel1ZIndex)">
                  <DiscussionSettings returnUrl={match.url} />
               </Modal>
            )}
         </ModalRoute>
      )
   }

   // TODO: Tmp BPW - see #1201
   if (initiative.type.id === bpwSponsorsTypeId) {
      return (
         <ModalRoute path={`${match.path}/-/settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <ObjectSettings.Modal
                  width="100%[a-b] 90%[c-d] 100%[e-f]"
                  height="100%"
                  maxWidth="1000px"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <ObjectSettings.Editor
                     descriptionInputMinHeight="300px"
                     displayMode={objectSettingsDisplayModes.DOUBLE_COLUMN}
                     editorToolbarConfig="advanced"
                     elements={[
                        'coverMedia',
                        'description',
                        'subtitle',
                        'url'
                     ]}
                     nameInputStyle={{
                        fontSize: '20px',
                        fontWeight: '500'
                     }}
                     onCancelButtonClick={onCloseTrigger}
                     onUpdate={() => history.push(match.url)}
                  />
               </ObjectSettings.Modal>
            )}
         </ModalRoute>
      )
   }

   if (initiative.class === 'post') {
      return (
         <ModalRoute path={`${match.path}/-/settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <ObjectSettings.Modal
                  width="100%[a-b] 90%[c-d] 100%[e-f]"
                  height="100%"
                  maxWidth="1000px"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <ObjectSettings.Editor
                     descriptionInputMinHeight="300px"
                     displayMode={objectSettingsDisplayModes.DOUBLE_COLUMN}
                     editorToolbarConfig="advanced"
                     elements={[
                        'coverMedia',
                        'description',
                        'publishDate',
                        'slug',
                        'subtitle',
                        'tags'
                     ]}
                     nameInputStyle={{
                        fontSize: '20px',
                        fontWeight: '500'
                     }}
                     onCancelButtonClick={onCloseTrigger}
                     onUpdate={() => history.push(match.url)}
                  />
               </ObjectSettings.Modal>
            )}
         </ModalRoute>
      )
   }

   if (initiative.class === 'event') {
      return (
         <ModalRoute path={`${match.path}/-/settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  overlayBackgroundColor="rgba(45,40,40,0.9)"
                  height="100%"
                  width="100%[a-b] 90%[c-d] 1000px[e-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <EventSettings returnUrl={match.url} />
               </Modal>
            )}
         </ModalRoute>
      )
   }

   // Default: For groups, legacy resources
   return (
      <ModalRoute path={`${match.path}/-/settings`}>
         {({ open, onCloseComplete, onCloseTrigger }) => (
            <Modal
               borderRadius="3px"
               overlayBackgroundColor="rgba(45,40,40,0.9)"
               height="100%"
               width="100%[a-b] 90%[c-d] 1000px[e-f]"
               onCloseComplete={onCloseComplete}
               onCloseTrigger={onCloseTrigger}
               open={open}>
               <GroupSettings returnUrl={match.url} />
            </Modal>
         )}
      </ModalRoute>
   )
}

export default TmpObjectSettingsRoutes
