import React from 'react'
import { HorizontalTicker } from 'react-infinite-ticker'

const customers = [
   {
      name: 'Government of Alberta',
      image: 'alberta.png'
   },
   {
      name: 'Westjet',
      image: 'westjet.png'
   },
   {
      name: 'Shell',
      image: 'shell.png'
   },
   {
      name: 'Solarwinds',
      image: 'solarwinds.png'
   },
   {
      name: 'Suncor',
      image: 'suncor.png'
   },
   {
      name: 'Imperial',
      image: 'imperial.png'
   },
   {
      name: 'Government of Canada',
      image: 'canada.png'
   },
   {
      name: 'Total',
      image: 'total.png'
   },
   {
      name: 'Alberta Teacher\'s Association',
      image: 'ata.png'
   },
   {
      name: 'ConocoPhillips',
      image: 'conoco.png'
   }
]

const LogoBlock = () => {
   return (
      <div className="w-full  bg-neutral-950 py-24 overflow-hidden">
         <div className="text-white/30 text-sm uppercase tracking-wider mb-16 text-center px-12">
            Communities hosts over 3 million members from over 190 countries
         </div>
         <HorizontalTicker duration={100000}>
            {customers.map(customer => (
               <div className="flex items-center justify-center w-28 h-24 py-[28px] bg-red-5002 mx-12">
                  <div className="w-full h-full bg-red-5002">
                     <img
                        src={`/assets/custom/communities/images/logos/${customer.image}`}
                        alt={customer.name}
                        className="w-full h-full object-contain opacity-50 hover:opacity-100 transition-opacity"
                     />
                  </div>
               </div>
            ))}
            {customers.map(customer => (
               <div className="flex items-center justify-center w-28 h-24 py-[28px] bg-red-5002 mx-12">
                  <div className="w-full h-full bg-red-5002">
                     <img
                        src={`/assets/custom/communities/images/logos/${customer.image}`}
                        alt={customer.name}
                        className="w-full h-full object-contain opacity-50 hover:opacity-100 transition-opacity"
                     />
                  </div>
               </div>
            ))}
         </HorizontalTicker>
      </div>
   )
}

export default LogoBlock
