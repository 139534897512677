// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, View } from 'oio-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ArrowUpIcon, TVMessagesOutline64Icon } from 'assets/icons'
import { useHighlightThreadEntry, useMessageList, useOnThreadEntryHighlighted, useThread } from 'src/core/graphql/hooks'
import TVControlsOverlay from 'src/sites/kits/Object/components/TV/ControlsOverlay'
import TVScreenSaver from 'src/sites/kits/Object/components/TV/Screensaver'
import { Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import MessageListItem from './MessageListItem'
import MessageDetailView from './MessageDetailView'
import ReplyDetailView from './ReplyDetailView'
import style from './style.less'

const TVFeed = ({ returnUrl }) => {
   const initialized = useRef(false)
   const viewRef = useRef()
   const currentMessagesLength = useRef()
   const [newMessageIndicatorIsVisible, setNewMessageIndicatorIsVisible] = useState(false)
   const [highlightedEntry, setHighlightedEntry] = useState({})
   const [showingScreensaver, setShowingScreensaver] = useState(false)

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { thread } = useThread({ id: initiative.thread.id })
   const { highlightThreadEntry } = useHighlightThreadEntry()
   const { threadEntryHighlighted } = useOnThreadEntryHighlighted({
      threadId: initiative.thread.id
   })

   const { messageList } = useMessageList({
      limit: 30,
      sortBy: 'dateDesc',
      threadId: initiative.thread.id,
      type: 'human'
   })

   const messages = messageList.items
   const highlightedMessageId = highlightedEntry?.messageId
   const highlightedReplyId = highlightedEntry?.replyId

   const scrollToMessage = (messageId) => {
      const messageElement = document.getElementById(`feed-message-${messageId}`)

      if (messageElement) {
         const scrollPosition = (messageElement.offsetTop + 100) - (window.innerHeight / 2)
         viewRef.current.scrollTo(0, scrollPosition)
      }
   }

   const highlightedMessage = messages.length && highlightedMessageId
      ? messages.find(m => m.id === highlightedMessageId)
      : null

   const highlightedReply = highlightedMessage
      ? highlightedMessage?.replies?.find(reply => reply.id === highlightedReplyId)
      : null

   const detailViewContainerClasses = [style.detailViewContainer, style.isHidden]
   let detailViewContent = null

   if (highlightedMessage) {
      detailViewContainerClasses.push(style.isVisible)
      detailViewContent = highlightedReply
         ? <ReplyDetailView message={highlightedMessage} reply={highlightedReply} />
         : <MessageDetailView message={highlightedMessage} />
   }

   const newMessageIndicatorClasses = [style.newMessageIndicator]
   if (highlightedMessage && newMessageIndicatorIsVisible) {
      newMessageIndicatorClasses.push(style.isVisible)
   } else {
      newMessageIndicatorClasses.push(style.isHidden)
   }

   const handleResumeFeed = async () => {
      try {
         await highlightThreadEntry({
            initiativeId: initiative.id,
            entryType: null,
            entryId: null
         })
      } catch {
         // toast.error('There was a problem resuming the feed')
      }
   }

   // const handleHighlightMessage = async (selectedMessageId) => {
   //    try {
   //       await highlightThreadEntry({
   //          initiativeId: initiative.id,
   //          entryType: 'message',
   //          entryId: selectedMessageId
   //       })
   //    } catch {
   //       // toast.error('There was a problem resuming the feed')
   //    }
   // }

   const screensaver = <TVScreenSaver />

   const overlay = (
      <TVControlsOverlay
         returnUrl={returnUrl}
         title={initiative.name}
         subtitle={`You are currently viewing the TV Message Feed. To control this feed, use the TV Remote available in the Options menu on the ${initiative.name} ${initiative.type.nameSingular} page.`}>
         {hideOverlay => (
            <div className="flex gap-4">
               {highlightedEntry?.messageId && (
                  <Button
                     onClick={() => {
                        setShowingScreensaver(false)
                        handleResumeFeed()
                        hideOverlay()
                     }}
                     name="Resume Feed"
                     color="#fff"
                     size="lg"
                     rounded
                     textColor="#222"
                     textSize="2.5"
                     height="var(--baseComponentHeight-xl)"
                  />
               )}
               {/* {!highlightedEntry?.messageId && (
                  <Select onChange={(e) => {
                     setShowingScreensaver(false)
                     handleHighlightMessage(e.target.value)
                     hideOverlay()
                  }}>
                     <option>Highlight</option>
                     {messages.map((m, index) => (
                        <option key={m.id} value={m.id}>{(messages.length - index)}</option>
                     ))}
                  </Select>
               )} */}
               <Button
                  onClick={() => {
                     setShowingScreensaver(showing => !showing)
                     hideOverlay()
                  }}
                  name={showingScreensaver ? 'Hide Screensaver' : 'Show Screensaver'}
                  color="#fff"
                  size="lg"
                  rounded
                  textColor="#222"
                  textSize="2.5"
                  height="var(--baseComponentHeight-xl)"
               />
            </div>
         )}
      </TVControlsOverlay>
   )

   useEffect(() => {
      const newMessagesLength = messages.length

      if (highlightedMessageId && newMessagesLength) {
         setTimeout(() => {
            scrollToMessage(highlightedMessageId)
         }, 0)
      } else {
         viewRef.current.scrollTop = 0
      }

      if (newMessagesLength > 0 && newMessagesLength > currentMessagesLength.current) {
         setNewMessageIndicatorIsVisible(true)
         setTimeout(() => setNewMessageIndicatorIsVisible(false), 2000)
      }

      currentMessagesLength.current = newMessagesLength
   }, [messages.length, highlightedMessageId])

   // Real-time highlight subscription
   useEffect(() => {
      setHighlightedEntry(threadEntryHighlighted)
   }, [threadEntryHighlighted?.messageId])

   // Initial load - see if there is already a highlighted
   // entry saved with the thread
   useEffect(() => {
      if (!initialized.current && thread?.highlightedEntry?.messageId) {
         setHighlightedEntry(thread.highlightedEntry)
         initialized.current = true
      }
   }, [thread])

   if (messages.length === 0) {
      return (
         <>
            <View
               ref={viewRef}
               display="flex"
               alignItems="center"
               justifyContent="center"
               width="100%"
               height="100%"
               className={style.nullFeedMessage}>
               <div>
                  <div className={style.icon}>
                     <TVMessagesOutline64Icon width="64px" height="64px" strokeWidth="4px" color="#fff" />
                  </div>
                  <View padding="24px">
                     <Title size="xl" color="#fff">{initiative.name}</Title>
                  </View>
                  No comments have been posted yet
               </div>
            </View>
            {showingScreensaver ? screensaver : null}
            {overlay}
         </>
      )
   }

   return (
      <View
         ref={viewRef}
         width="100%"
         height="100%"
         padding="60px 120px 120px 60px"
         // backgroundColor="#21272d"
         backgroundColor="#000"
         scroll="on">
         <View
            position="absolute"
            top="0px"
            left="100px"
            className={classNames(newMessageIndicatorClasses)}>
            <ArrowUpIcon width="36px" height="36px" color="rgba(255,255,255,0.7)" />
         </View>
         {messages.map((message, index) => {
            const mode = highlightedMessage ? 'minimized' : 'normal'
            const highlighted = highlightedMessage && message.id === highlightedMessage.id

            return (
               <MessageListItem
                  key={message.id}
                  message={message}
                  messageNumber={messages.length - index}
                  mode={mode}
                  highlighted={highlighted}
               />
            )
         })}
         <View
            className={classNames(detailViewContainerClasses)}
            top="0px"
            left="185px"
            right="0px"
            bottom="0px">
            <View
               position="absolute"
               top="0px"
               left="0px"
               right="0px"
               bottom="0px"
               padding="120px 156px 90px 120px[a-d] 150px 240px 90px 120px[e-f]">
               {detailViewContent}
            </View>
         </View>
         {!showingScreensaver && <div className={style.viewTopGradient} />}
         {showingScreensaver ? screensaver : null}
         {overlay}
      </View>
   )
}

TVFeed.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default TVFeed
